html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 100%;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  font-family: inherit;
  font-size: 100%;
  background: inherit;
  border: none;
  padding: 0;
}

cite {
  font-style: inherit;
}

button:focus {
  outline: none;
}

body::-webkit-scrollbar {
  display: none;
}
