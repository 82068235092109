/* generic delighter start state and transition */
[data-delighter] {
  transition: all .5s ease-out;
  opacity: 0;
}
/* generic delighter end state */
[data-delighter].started {
  transform: translateX(0);
  opacity: 1;
}

/* delighter position offsets */
[data-delighter="d-right"] {
  transform: translateX(20px);
}
[data-delighter="d-left"] {
  transform: translateX(-20px);
}
[data-delighter="d-up"] {
  transform: translateY(-10px);
}
[data-delighter="d-down"] {
  transform: translateY(10px);
}
[data-delighter="fade"] {
  transform: translateY(20px);
}