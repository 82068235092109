@keyframes visual {
  0% { transform: scale(1); }
  50% { transform: scale(1.15); }
  100% { transform: scale(1); }
}

@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(-20px); }
  20% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1;  transform: translateY(0);}
}

@keyframes nudge {
  0% { transform: translateX(0px); }
  50% { transform: translateX(10px); }
  100% { transform: translateX(0);}
}
