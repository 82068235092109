@font-face {
  font-family: Benton Modern One;
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/benton-modern-one.woff2) format("woff2");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2212,U+2215;
  font-display: swap;
}

@font-face {
  font-family: Benton Modern One;
  font-style: italic;
  font-weight: 400;
  src: url(./fonts/benton-modern-one_italic.woff2) format("woff2");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2212,U+2215;
  font-display: swap;
}

@font-face {
  font-family: Benton Sans;
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/benton-sans.woff2) format("woff2");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2212,U+2215;
  font-display: swap;
}

@font-face {
  font-family: Benton Sans;
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/benton-sans_bold.woff2) format("woff2");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2212,U+2215;
  font-display: swap;
}

@font-face {
  font-family: Glypha LT Std;
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/glypha-lt-std.woff2) format("woff2");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2212,U+2215;
  font-display: swap;
}

@font-face {
  font-family: Glypha LT Std;
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/glypha-lt-std_bold.woff2) format("woff2");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2212,U+2215;
  font-display: swap;
}

@font-face {
  font-family: Sharp Sans Display No2;
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/sharp-sans-display-no2.woff2) format("woff2");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2212,U+2215;
  font-display: swap;
}

@font-face {
  font-family: Sharp Sans Display No2;
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/sharp-sans-display-no2_bold.woff2) format("woff2");
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2212,U+2215;
  font-display: swap;
}
